<template>
  <div>
    <audio id="alarmA" :src="require('@/assets/alarm.mp3')" loop="true" />
    <div class="sosCall">
      <template v-for="(item, index) in sosTypeList">
        <div class="sosCallBox" :key="index">
          <span>{{ item.sosName }}</span>
          <div class="sosMsg">
            电梯名称：
            {{ item.name }}
          </div>
          <div class="sosMsg">
            电梯位置：
            {{ item.address }}
          </div>
          <div style="margin-top: 10px">
            <template v-if="item.c_id">
              <el-button type="info" size="small" @click="closeSOS(item)"
                >关闭({{ item.time }})</el-button
              >
            </template>
            <template v-else>
              <template
                v-if="
                  item.type === 95 ||
                  item.type === 0 ||
                  item.type === 10 ||
                  item.type === 7 ||
                  item.type === 3 ||
                  item.type === 4 ||
                  item.type === 5 ||
                  item.type === 11 ||
                  item.type === 12 ||
                  item.type === 80
                "
              >
                <el-button type="primary" size="small" @click="answerSOS(item)"
                  >接听({{ item.time }})</el-button
                >
                <el-button type="info" size="small" @click="closeSOS(item)"
                  >拒接</el-button
                >
              </template>
              <template v-else>
                <el-button type="info" size="small" @click="closeSOS(item)"
                  >关闭</el-button
                >
              </template>
            </template>
          </div>
        </div>
      </template>
      <el-dialog
        v-if="visible"
        :visible.sync="visible"
        :close-on-click-modal="false"
        :show-close="showClose"
        width="950px"
        top="8vh"
        :modal="false"
        @closed="bclose"
      >
        <div style="padding: 0 20px" class="scrollElement">
          <EditionC2 :jkData="jkData" :sosId="sosId" :id="device_id" :percentage="percentage" @changePercentage="changePercentage"@bclose="bclose" />
        </div>
        <div slot="footer" class="dialog-footer"></div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import EditionC2 from "../../../components/editionC2";
let sosTime;
export default {
  data() {
    return {
      // 报警信息
      sosTypeList: [],
      jkData: null, // 球机推拉流
      visible: false,
      sosId: "",
      showClose: false,
      tpList: [],
      
      percentage: 0
    };
  },
  components: {
    EditionC2,
  },
  mounted() {

    this.init();
  },
  beforeDestroy() {
    clearInterval(sosTime);
  },
  methods: {
    bclose() {
      this.visible = false;
    },
    // 拒接
    closeSOS(data) {
      this.sosTypeList = this.sosTypeList.filter(
        (item) => item.sosId !== data.sosId
      );
      if (this.sosTypeList.length === 0) {
        document.getElementById("alarmA").pause();
      }
    },

    changePercentage(data) {
      console.log(data, 'data');
      this.percentage = data
    },
    getStreamStatus(data) {
      console.log(data,'jkData');
      this.device_id = data.device_id
      this.$http
      .get(`api/ele/web/getStreamStatus?id=${this.device_id}`)
      .then((res) => {
        if (res.data.success) {
          this.percentage = res.data.data ? 75 : 50
        } else {
        this.$message.error(res.data.msg)
        }
      })
    },

    // 接听
    answerSOS(data) {
      this.sosTypeList = this.sosTypeList.filter(
        (item) => item.sosId !== data.sosId
      );
      document.getElementById("alarmA").pause();
      this.sosId = data.sosId;
      this.visible = true;
      setTimeout(() => {
        this.showClose = true;
      }, 2000);
      const agentData = {
        act: "ca_start_rtsp",
        device_id: data.code,
      };
      this.socketApi.sendSock(agentData, (e) => {
        if (e.status) {
          if (e.hb) {
            this.percentage = 25
          }
          if (e.cmd === "ca_start_rtsp") {
            const data = JSON.parse(e.data);
            console.log("监控data", data);
            
            this.percentage = 50
            this.getStreamStatus(JSON.parse(e.data))
            this.jkData = data;
          }
        } else {
          this.$message.error(e.msg);
        }
      });
      console.log(agentData, "agentData");
    },
    init() {
      this.$http
        .post("/api/system/web/dict/dictionary?codes=SOS_TYPE")
        .then((res) => {
          if (res.data.success) {
            this.tpList = res.data.data.SOS_TYPE;
            sosTime = setInterval(() => {
              this.sosTypeList.forEach((qs, ind) => {
                qs.time -= 1;
                if (qs.time <= 0) {
                  this.sosTypeList.splice(ind, 1);
                }
              });
              this.socketApi.sosPrompt.forEach((qs) => {
                qs.time = 30;
                qs.typeShow = true;
                if (qs.type === -1) {
                  qs.sosName = `${qs.terminalSosName}报警`;
                } else {
                  qs.sosName = this.getType(qs.type);
                }
                this.sosTypeList.push(qs);
              });
              this.sosTypeList=this.sosTypeList.filter(item=>{
                let data=this.getSosConfigObj(item)
                return data&&data.push==1
              })
              let voiceS=this.sosTypeList.filter(item=>{
                let data=this.getSosConfigObj(item)
                return data&&data.voice==1
              })
              if (
                this.sosTypeList.length > 0 &&
                !this.visible &&
                !this.$global.openJK &&
                voiceS.length>0
              ) {
                document.getElementById("alarmA").loop = true;
                document.getElementById("alarmA").play();
              } else {
                document.getElementById("alarmA").pause();
              }

              this.socketApi.clearSos();
            }, 1000);
          }
        });
    },
    getType(type) {
      let obj = "未知报警";
      this.tpList.forEach((qs) => {
        if (qs.value == type) {
          obj = qs.label;
          return obj;
        }
      });
      return obj;
    },



    getSosConfigObj(data){
      const sosConfig=JSON.parse(sessionStorage.getItem("sosConfig"))
      let arr=sosConfig.filter(item=>item.sosType==data.type)
      return arr[0]
    }
  },
};
</script>

<style lang="scss" scoped>
.sosCall {
  position: absolute;
  top: 100px;
  left: 540px;
  z-index: 999;
  width: calc(100vw - 1080px);
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.sosCallBox {
  max-width: 290px;
  // height: 150px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px 10px 0;
  background-color: #f0ffff;
}

.sosCallBox span {
  font-size: 18px;
  font-weight: bold;
}

.sosMsg {
  margin-top: 10px;
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
