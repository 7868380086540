<template>
    <el-dialog
        custom-class="custom-dialog"
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        width="1400px"
        top="0"
        @close="closeDialog">
        <div class="scrollElement">
            <div class="header">
                <div class="header-item">
                    <span>电梯名称:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="name"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>电梯注册代码:</span>
                    <el-input
                        placeholder="请输入内容"
                        v-model="code"
                        clearable>
                    </el-input>
                </div>
                <div class="header-item">
                    <span>应急状态:</span>
                    <el-select v-model="sosStatus" filterable placeholder="请选择">
                        <el-option
                        v-for="item in sosStatusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="header-item">
                    <span>应急类型:</span>
                    <el-select v-loading="sosTypeLoading" v-model="sosType" filterable placeholder="请选择">
                        <el-option
                        v-for="item in statusList"
                        :key="item.dictKey"
                        :label="item.dictValue"
                        :value="item.dictKey">
                        </el-option>
                    </el-select>
                </div>

                <el-button type="primary" @click="getTable">查询</el-button>
                <el-button type="primary" @click="reset">重置</el-button>
            </div>

            <div>
                <el-table
                v-loading="loading"
                :data="tableData"
                stripe
                ref="multipleTable"
                height="500px"
                style="width: 100%">
                    <el-table-column type="index" label="序号" width="50px" />
                    <el-table-column align="center" prop="eleName" label="电梯名称" min-width="200px" />
                    <el-table-column align="center" prop="sosTime" label="报警时间" width="150px" />
                    <el-table-column align="center" prop="sosFloor" label="报警楼层" width="150px" />
                    <el-table-column align="center" prop="sosType" label="应急类型" width="150px" >
                        <template slot-scope="scope">
							{{getStatusText(scope.row.sosType)}}
						</template>
					</el-table-column>
                    <el-table-column align="center" prop="sosStatus" label="应急状态" width="150px" >
                        <template slot-scope="scope">
							{{getSosStatusText(scope.row.sosStatus)}}
						</template>
					</el-table-column>
                    <el-table-column fixed="right" align="center" prop="address" label="操作" width="80px">
						<template slot-scope="scope">
							<el-button @click="check(scope.row)" type="primary" size="small">查看</el-button>
						</template>
					</el-table-column>
                </el-table>
                <Pagination :total="total" :page.sync="current" :limit.sync="size" @pagination="dictionarPage" />
            </div>
        </div>
        <div class="null"></div>

        <elevator-warning-detail v-if="dioDetails" :detailsItem="detailsItem" :initDioDetailsItem="dioDetailsItem"  @closeDialog="closeDetail"/>
    </el-dialog>
</template>
<script>
import Pagination from '@/components/Pagination'
import ElevatorWarningDetail from './elevator-warning-detail.vue'
export default {
    props:{
        dialogTitle:String,
        start:Number|String,
        end:Number|String,
        openType:Number|String,
    },
    components: {
        Pagination,
        ElevatorWarningDetail
    },
    data() {
        return {
            dialogVisible:true,
            loading:false,
            //表格筛选信息
            name:'',
            code:'',
            sosStatus:'',
            sosType:'',
            sosTypeLoading:true,
            sosStatusOptions:[
                {
                    value:1,
                    label:'待处理'
                },
                {
                    value:2,
                    label:'已关闭'
                },
                {
                    value:3,
                    label:'进行中'
                },
                {
                    value:4,
                    label:'已完成'
                },
            ],
            //表格数据及分页选项
            tableData: [],
            total:0,
            size:10,
            current:1,
            //报警详情
            detailsItem: {
                seleId: 1,
                seleArr: [{
                    value: 1,
                    label: '报警消息'
                }, {
                    value: 2,
                    label: '运行参数'
                }, {
                    value: 3,
                    label: '处置流程'
                }]
            },
            dioDetails: false,
			dioDetailsItem: null,
            //应急状态字典
            statusList:[],
        }
    },
    computed:{
        params(){
            return {
                current:this.current,
                size:this.size,
                eleName:this.name,
                eleCode:this.code,
                sosStatus:this.sosStatus,
                sosType: this.sosType,
                start:this.start,
                end:this.end,
                selectType: 2
            }
        }
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog',{
                openType:this.openType
            })
        },
        reset(){
            this.name=''
            this.code=''
            this.sosStatus=''
            this.sosType=''
            this.getTable()
        },
        // 分页
        dictionarPage(data) {
            this.current = data.page
            this.size = data.limit
            this.getTable()
        },
        //获取状态字典
        async getDictionary(code='SOS_TYPE') {
            const res=await this.$http.get(`/api/system/web/dict/dictionary?code=${code}`)
            if(res.status==200&&res.data.code==200){
                this.statusList=res.data.data.filter(item => item.dictKey != 6 && item.dictKey != 15)
            }
            this.sosTypeLoading=false
        },
        getStatusText(status){
            const list=this.statusList.filter(item=>item.dictKey==status)
            return list.length>0?list[0].dictValue:status
        },
        getSosStatusText(status){
            const list=this.sosStatusOptions.filter(item=>item.value==status)
            return list.length>0?list[0].label:status
        },
        getTable(){
            this.loading=true
            this.$http.post('/api/ele/web/sos/getList', this.params).then((res) => {
                if(res.status==200&&res.data.code==200){
                    this.tableData=res.data.data.records.map((qs) => {
                        qs.sosTypeName = this.getStatusText(qs.sosType)
                        qs.sosStatusName = this.getSosStatusText(qs.sosStatus)
                        return qs
                    })
                    this.total=res.data.data.total
                    this.$nextTick(() => {
                        if (this.total > 0 && this.$refs.multipleTable) {
                            this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
                        }
                    })
                }
                this.loading=false
            })
        },
        // 查看
        check(item) {

            this.$http.post("/api/ele/web/sos/getInfo", {
                id: item.id,
                sosTime: item.sosTime ? item.sosTime : ''
            })
            .then((res) => {
                if (res.data.success) {
                    res.data.data.sosVideo.forEach((qs) => {
                        let url = qs.videoUrl.split('?');
                        qs.videoUrl = url[0]
                        qs.videoName = url[1].split('&')[0].split('=')[1]
                    })
                    res.data.data.sosTypeName = item.sosType == -1 ? item.sosText : item.sosTypeName
                    res.data.data.sosStatusName = this.sosStatusOptions.find(item => item.value === res.data.data
                            .sosStatus) ? this.sosStatusOptions.find(item => item.value === res.data.data.sosStatus)
                        .label : ''
                    res.data.data.speed = res.data.data.speed !== '' ? parseFloat(res.data.data.speed) : 0
                    res.data.data.speedarr = res.data.data.speedRecord.split(',');

                    this.detailsItem.seleId = 1
                    this.dioDetailsItem = res.data.data
                    this.dioDetails = true

                } else {
                    this.$message.error(res.data.msg)
                }
            })
        },
        closeDetail(){
            this.dioDetails=false
            this.getTable()
        },
    },
    created(){
        this.getDictionary()
        .then(()=>{
            this.getTable()
        })
    }
}
</script>
<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    // overflow-y: scroll; 
    padding-right: 20px
}
.null{
    height: 90px;
}
.header,.header-item{
    display: flex;
}
.header{
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 20px;
}
.header-item{
    margin: 0 20px 20px 0;
    align-items: center;
    span{
        white-space: nowrap;
        margin-right: 8px;
    }
}

.scrollElement /deep/ .el-table__fixed-right {
    background: rgba(0,0,0,0) !important;
}
.scrollElement /deep/ .el-table__fixed-right >>> .el-table__row >>> .el-table__cell:last-child{
    background: #1e2d4f ;
}
</style>