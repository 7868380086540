var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('audio',{attrs:{"id":"alarmA","src":require('@/assets/alarm.mp3'),"loop":"true"}}),_c('div',{staticClass:"sosCall"},[_vm._l((_vm.sosTypeList),function(item,index){return [_c('div',{key:index,staticClass:"sosCallBox"},[_c('span',[_vm._v(_vm._s(item.sosName))]),_c('div',{staticClass:"sosMsg"},[_vm._v(" 电梯名称： "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"sosMsg"},[_vm._v(" 电梯位置： "+_vm._s(item.address)+" ")]),_c('div',{staticStyle:{"margin-top":"10px"}},[(item.c_id)?[_c('el-button',{attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.closeSOS(item)}}},[_vm._v("关闭("+_vm._s(item.time)+")")])]:[(
                item.type === 95 ||
                item.type === 0 ||
                item.type === 10 ||
                item.type === 7 ||
                item.type === 3 ||
                item.type === 4 ||
                item.type === 5 ||
                item.type === 11 ||
                item.type === 12 ||
                item.type === 80
              )?[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.answerSOS(item)}}},[_vm._v("接听("+_vm._s(item.time)+")")]),_c('el-button',{attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.closeSOS(item)}}},[_vm._v("拒接")])]:[_c('el-button',{attrs:{"type":"info","size":"small"},on:{"click":function($event){return _vm.closeSOS(item)}}},[_vm._v("关闭")])]]],2)])]}),(_vm.visible)?_c('el-dialog',{attrs:{"visible":_vm.visible,"close-on-click-modal":false,"show-close":_vm.showClose,"width":"950px","top":"8vh","modal":false},on:{"update:visible":function($event){_vm.visible=$event},"closed":_vm.bclose}},[_c('div',{staticClass:"scrollElement",staticStyle:{"padding":"0 20px"}},[_c('EditionC2',{attrs:{"jkData":_vm.jkData,"sosId":_vm.sosId,"id":_vm.device_id,"percentage":_vm.percentage},on:{"changePercentage":_vm.changePercentage,"bclose":_vm.bclose}})],1),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"})]):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }