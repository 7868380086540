<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    append-to-body
    width="950px"
    top="20vh"
    center
    @close="closeDialog"
  >
    <el-form
      ref="elevatorForm"
      :model="elevatorForm"
      :rules="elevatorFormRules"
      label-width="150px"
      label-position="right"
    >
    <!-- :rules="elevatorFormRules" -->
      <el-tabs v-model="activeName">
        <el-tab-pane
          label="主要资料"
          name="first"
        >
          <div
            class="searchBox scrollElement"
            style="height: 50vh;overflow-y: scroll;"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="电梯名称:"
                  label-width="140px"
                  prop="name"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.name"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电梯注册代码:"
                  label-width="140px"
                  prop="code"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.code"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="救援识别码:"
                  label-width="140px"
                  prop="rescueCode"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.rescueCode"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="出厂编号:"
                  label-width="140px"
                  prop="factoryNumber"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.factoryNumber"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="AI摄像头ID:"
                  label-width="140px"
                  prop="deviceCode"
                >
                  <el-input
                    class="wid90"
                    maxlength="16"
                    v-model="elevatorForm.deviceCode"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
               <el-col :span="12">
                <el-form-item
                  label="所属小区:"
                  label-width="140px"
                  prop="plotId"
                >
                  <el-select
                    v-model="elevatorForm.plotId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changePlot"
                  >
                    <el-option
                      v-for="item in plotArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item
                  label="电梯数据终端ID:"
                  label-width="140px"
                  prop="terminalId"
                  class="terminalType"
                >
                  <el-input
                    class="wid90"
                    maxlength="16"
                    v-model="elevatorForm.terminalId"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  >
                    <el-select
                      v-model="elevatorForm.terminalType"
                      slot="prepend"
                      placeholder="请选择"
                    >
                      <el-option
                        label="R3+"
                        :value="1"
                      ></el-option>
                      <el-option
                        label="C2+"
                        :value="2"
                      ></el-option>
                    </el-select>
                  </el-input>
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row>
              <!-- <el-col :span="12">
                <el-form-item
                  label="所属区域:"
                  label-width="140px"
                  prop="areaCodeArr"
                >
                  <el-cascader
                    :props="props"
                    :options="$mapCode"
                    v-model="elevatorForm.areaCodeArr"
                    :disabled="type === 'check'"
                    class="einp"
                    collapse-tags
                    clearable
                  >
                  </el-cascader>
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="12">
                <el-form-item
                  label="所属小区:"
                  label-width="140px"
                  prop="plotId"
                >
                  <el-select
                    v-model="elevatorForm.plotId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changePlot"
                  >
                    <el-option
                      v-for="item in plotArr"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item
                  label="报警短信推送:"
                  label-width="140px"
                  prop="smsSos"
                >
                  <el-select
                    v-model="elevatorForm.smsSos"
                    :disabled="roleId!='3'||type === 'check'"
                    class="wid90"
                    filterable
                    placeholder="请选择"
                  >
                    <el-option
                      label="不推送报警短信"
                      :value="0"
                    />
                    <el-option
                      label="推送报警短信"
                      :value="1"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="楼栋:"
                  label-width="140px"
                  prop="floorDong"
                >
                <el-input
                    class="wid90"
                    v-model="elevatorForm.floorDong"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="单元:"
                  label-width="140px"
                  prop="eleUnit"
                >
                <el-input
                    class="wid90"
                    v-model="elevatorForm.eleUnit"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电梯号:"
                  label-width="140px"
                  prop="eleCode"
                >
                <el-input
                    class="wid90"
                    v-model="elevatorForm.eleCode"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>

              <el-col :span="24">
                <el-form-item
                  label="电梯安装位置:"
                  label-width="140px"
                  prop="eleAddress"
                >
                  <!-- <el-input
                    class="wid90"
                    v-model="elevatorForm.eleAddress"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  /> -->
                  <el-input
                    :placeholder="elevatorForm.eleAddress || '暂无内容'"
                    v-model="elevatorForm.eleAddress"
                    class="wid96"
                    disabled
                  >
                    <el-button
                      :disabled="type === 'check'"
                      slot="append"
                      type="primary"
                      @click="chooseAddress"
                    >选择安装位置</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="相关单位"
          name="second"
        >
          <div
            class="searchBox scrollElement"
            style="height: 50vh;overflow-y: scroll;"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="维保单位:"
                  prop="maintainUnitId"
                >
                  <el-select
                    v-model="elevatorForm.maintainUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changeUnit('maintain')"
                  >
                    <el-option
                      v-for="item in wbUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="维保人员:"
                  prop="maintainUserIds"
                >
                  <el-input
                    :placeholder="elevatorForm.maintainUserNames || '暂无内容'"
                    v-model="elevatorForm.maintainUserNames"
                    class="wid90"
                    disabled
                  >
                    <el-button
                      :disabled="!elevatorForm.maintainUnitId || type === 'check'"
                      slot="append"
                      type="primary"
                      @click="selectElevator('MAINTAIN_UNIT', elevatorForm.maintainUnitId)"
                    >选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="物业单位:"
                  prop="propertyUnitId"
                >
                  <el-select
                    v-model="elevatorForm.propertyUnitId"
                    :disabled="type === 'check' || propertyDisabled"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changeUnit('property')"
                  >
                    <el-option
                      v-for="item in wyUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电梯安全员:"
                  prop="propertyUnitUserIds"
                >
                  <el-input
                    :placeholder="elevatorForm.propertyUnitUserNames || '暂无内容'"
                    v-model="elevatorForm.propertyUnitUserNames"
                    class="wid90"
                    disabled
                  >
                    <el-button
                      :disabled="!elevatorForm.propertyUnitId || type === 'check'"
                      slot="append"
                      type="primary"
                      @click="selectElevator('TENEMENT_UNIT', elevatorForm.propertyUnitId)"
                    >选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="物业单位内部编号:"
                  prop="propertyUnitInternalNumber"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.propertyUnitInternalNumber"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="检验单位:">
                  <el-select
                    v-model="elevatorForm.inspectionUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in jyanUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="应急救援单位:"
                  prop="emergencyRescueUnitId"
                >
                  <el-select
                    v-model="elevatorForm.emergencyRescueUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changeUnit('emergency')"
                  >
                    <el-option
                      v-for="item in jyUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="救援人员:"
                  prop="emergencyRescueUserIds"
                >
                  <el-input
                    :placeholder="elevatorForm.emergencyRescueUserNames || '暂无内容'"
                    v-model="elevatorForm.emergencyRescueUserNames"
                    class="wid90"
                    disabled
                  >
                    <el-button
                      :disabled="!elevatorForm.emergencyRescueUnitId || type === 'check'"
                      slot="append"
                      type="primary"
                      @click="selectElevator('RESCUE_UNIT', elevatorForm.emergencyRescueUnitId)"
                    >选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="登记机关:"
                  prop="registrationAuthorityId"
                >
                  <el-select
                    v-model="elevatorForm.registrationAuthorityId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in djUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="登记证编号:"
                  prop="registeredCode"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.registeredCode"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="安装单位:">
                  <el-select
                    v-model="elevatorForm.installUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in azUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="产权单位:">
                  <el-select
                    v-model="elevatorForm.propertyRightUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in cqUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="保险单位:"
                  prop="insuranceUnitId"
                >
                  <el-select
                    v-model="elevatorForm.insuranceUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                    @change="changeUnit('insurance')"
                  >
                    <el-option
                      v-for="item in bxUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="保险人员:"
                  prop="insuranceUnitUserIds"
                >
                  <el-input
                    :placeholder="elevatorForm.insuranceUnitUserNames || '暂无内容'"
                    v-model="elevatorForm.insuranceUnitUserNames"
                    class="wid90"
                    disabled
                  >
                    <el-button
                      :disabled="!elevatorForm.insuranceUnitId || elevatorForm.insuranceUnitId === 100 || type === 'check'"
                      slot="append"
                      type="primary"
                      @click="selectElevator('INSURANCE_UNIT', elevatorForm.insuranceUnitId)"
                    >选择人员</el-button>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="制造单位:">
                  <el-select
                    v-model="elevatorForm.manufacturingUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in zzUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="制造许可证编号:"
                  prop="manufacturingCode"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.manufacturingCode"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="厂商单位:">
                  <el-select
                    v-model="elevatorForm.manufacturerUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in csUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="街道单位:">
                  <el-select
                    v-model="elevatorForm.streetUnitId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in jdUnit"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="电梯参数"
          name="third"
        >
          <div
            class="searchBox scrollElement"
            style="height: 50vh;overflow-y: scroll;"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="电梯品牌:"
                  prop="brandId"
                >
                  <el-select
                    v-model="elevatorForm.brandId"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in eleBrand"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电梯型号:"
                  prop="eleModel"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.eleModel"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="电梯类型:">
                  <el-select
                    v-model="elevatorForm.eleType"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in eleType"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="生产方式:">
                  <el-select
                    v-model="elevatorForm.productionMode"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in genneration"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="电梯停靠站数:"
                  prop="floorHeight"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.floorHeight"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="地下楼层数:"
                  prop="underFloor"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.underFloor"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="站数:"
                  prop="standNumber"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.standNumber"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="门数:"
                  prop="doorNumber"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.doorNumber"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="使用状态:">
                  <el-select
                    v-model="elevatorForm.eleState"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in useState"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="使用场所分类:" prop="elePlaceType">
                  <el-select
                    v-model="elevatorForm.elePlaceType"
                    :disabled="type === 'check' || (type !== 'add' && elevatorForm.elePlaceType == 'GOVERNMENT_ORGAN' && adminInfo.userName != 'admin')"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in usePlace"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="电梯品种:">
                  <el-select
                    v-model="elevatorForm.varieties"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in eleVarieties"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯控制方式:">
                  <el-select
                    v-model="elevatorForm.controlMode"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in eleControlMode"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="电梯注册日期:">
                  <el-date-picker
                    v-model="elevatorForm.registrationDate"
                    :disabled="type === 'check'"
                    class="wid90"
                    type="date"
                    placeholder="请输入"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯大修/改造日期:">
                  <el-date-picker
                    v-model="elevatorForm.installTransformDate"
                    :disabled="type === 'check'"
                    class="wid90"
                    type="date"
                    placeholder="请输入"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="额定负载(kg):"
                  prop="atedLoad"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.atedLoad"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电梯门宽度:"
                  prop="doorWidth"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.doorWidth"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  >
                    <template slot="append">米</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12" v-if="type !== 'add'">
                <el-form-item
                  label="电梯风险等级:"
                  prop="riskLevel"
                >
                  <el-select
                    v-model="elevatorForm.riskLevel"
                    disabled
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in riskLevelList"
                      :key="item.value"
                      :label="item.label"
                      :value="Number(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="维保参数"
          name="fourth"
        >
          <div
            class="searchBox scrollElement"
            style="height: 50vh;overflow-y: scroll;"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item label="维保等级:">
                  <el-select
                    v-model="elevatorForm.maintainLevel"
                    :disabled="type === 'check'"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      label="1级"
                      value="1"
                    />
                    <el-option
                      label="2级"
                      value="2"
                    />
                    <el-option
                      label="3级"
                      value="3"
                    />
                    <el-option
                      label="4级"
                      value="4"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="年检时间:">
                  <el-date-picker
                    v-model="elevatorForm.inspectionDate"
                    :disabled="type === 'check'"
                    class="wid90"
                    type="date"
                    placeholder="请输入"
                    value-format="yyyy-MM-dd HH:mm:ss"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="服务平台二维码内容:"
                  prop="servicePlatformQrCode"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.servicePlatformQrCode"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="维保签到二维码:">
                  <FileUpload
                    v-if="type !== 'check'"
                    :md5s.sync="elevatorForm.qrCodeSignImage"
                    :imgSize="true"
                    :limit="1"
                    url='/api/file/web/upload'
                  />
                  <template v-else>
                    <el-image
                      v-if="elevatorForm.qrCodeSignImage"
                      style="width: 100px; height: 100px"
                      :src="elevatorForm.qrCodeSignImage"
                      :fit="'contain'"
                    />
                  </template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="电梯使用标志:"
                  prop="elevatorUseSign"
                >
                  <FileUpload
                    v-if="type !== 'check'"
                    :md5s.sync="elevatorForm.elevatorUseSign"
                    :multiple="false"
					:imgSize="true"
                    :limit="1"
                    url='/api/file/web/upload'
                  />
                  <template v-else>
                    <el-image
                      v-if="elevatorForm.elevatorUseSign"
                      style="width: 100px; height: 100px"
                      :src="elevatorForm.elevatorUseSign"
                      :fit="'contain'"
                    />
                  </template>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <el-tab-pane
          label="物联网设备相关设置"
          name="fifth"
        >
          <div
            class="searchBox scrollElement"
            style="height: 50vh;overflow-y: scroll;"
          >
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="超速报警阈值:"
                  prop="overSpeed"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.overSpeed"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  >
                    <template slot="append">m/s</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="最大速度:"
                  prop="maxSpeed"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.maxSpeed"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                    @change="changemaxSpeed"
                  >
                    <template slot="append">m/s</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item label="SIM卡开卡时间:">
                  <el-date-picker
                    v-model="elevatorForm.simStartTime"
                    :disabled="type === 'check'"
                    class="wid90"
                    type="date"
                    placeholder="请输入"
                    @change='changeTime'
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="SIM卡到期时间:">
                  <el-date-picker
                    v-model="elevatorForm.simEndTime"
                    :disabled="type === 'check'"
                    class="wid90"
                    type="date"
                    placeholder="请输入"
                    @change='changeTime'
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="报警阀值(加速度):"
                  prop="maxSpeedA"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.maxSpeedA"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  >
                    <template slot="append">m/s²</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="固定IP:"
                  prop="deviceIp"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.deviceIp"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item
                  label="网关:"
                  prop="deviceGateway"
                >
                  <el-input
                    class="wid90"
                    v-model="elevatorForm.deviceGateway"
                    :disabled="type === 'check'"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="DNS:"
                  prop="deviceDns"
                >
                <el-input
                  class="wid90"
                  v-model="elevatorForm.deviceDns"
                  :disabled="type === 'check'"
                  placeholder="请输入"
                />
                <!-- <el-select
                    v-model="elevatorForm.deviceDns"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in DNSList"
                      :key="item.value"
                      :label="item.label"
                      :value="Number(item.value)"
                    />
                  </el-select> -->
                </el-form-item>
              </el-col>
            </el-row>

            <el-row v-if="adminInfo.userName == 'admin'">
              <el-col :span="12">
                <el-form-item
                  label="人体阀值:"
                  prop="headValue"
                >
                  <el-input-number
                    class="wid90"
                    v-model="elevatorForm.headValue"
                    :min="0"
                    :max="1"
                    :precision="2"
                    :step="0.1"
                    :disabled="type === 'check'"
                    label="请输入"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="开关门阀值:"
                  prop="openValue"
                >
                  <el-input-number
                    class="wid90"
                    v-model="elevatorForm.openValue"
                    :min="0"
                    :max="1"
                    :precision="2"
                    :step="0.1"
                    :disabled="type === 'check'"
                    label="请输入"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="adminInfo.userName == 'admin'">
              <el-col :span="12">
                <el-form-item
                  label="电瓶车阀值:"
                  prop="bikeValue"
                >
                  <el-input-number
                    class="wid90"
                    v-model="elevatorForm.bikeValue"
                    :min="0"
                    :max="1"
                    :precision="2"
                    :step="0.1"
                    :disabled="type === 'check'"
                    label="请输入"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item
                  label="静止阈值:"
                  prop="staticHeadProb"
                >
                  <el-input-number
                    class="wid90"
                    v-model="elevatorForm.staticHeadProb"
                    :min="0.01"
                    :max="0.50"
                    :precision="2"
                    :step="0.1"
                    :disabled="type === 'check'"
                    label="请输入"/>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row v-if="adminInfo.userName == 'admin'">
              <el-col :span="12">
                <el-form-item
                  label="静止阈值:"
                  prop="staticHeadProb"
                >
                  <el-input-number
                    class="wid90"
                    v-model="elevatorForm.staticHeadProb"
                    :min="0"
                    :max="1"
                    :precision="2"
                    :step="0.1"
                    :disabled="type === 'check'"
                    label="请输入"/>
                </el-form-item>
              </el-col>
            </el-row> -->


            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="人像模糊:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.portraitBlur">
                      <el-radio :label="1">开</el-radio>
                      <el-radio :label="0">关</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.portraitBlur === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="人体画框:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.headBorder">
                      <el-radio :label="1">开</el-radio>
                      <el-radio :label="0">关</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.headBorder === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="电瓶车框:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikeBorder">
                      <el-radio :label="1">开</el-radio>
                      <el-radio :label="0">关</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikeBorder === 1 ? '开' : '关' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="adminInfo.userName == 'admin'">
                <el-form-item label="开关门框:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.doorBorder">
                      <el-radio :label="1">开</el-radio>
                      <el-radio :label="0">关</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.doorBorder === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>

              <el-col :span="12">
                <el-form-item label="主动报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.sosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.sosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="AI识别:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.aiIdentifySwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.aiIdentifySwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="语音报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.audioSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.audioSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="安全回路报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.safetyCircuitSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.safetyCircuitSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="关门故障:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.doorOpenSosSwitch" >
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.doorOpenSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开锁区域外停梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.overFloorSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.overFloorSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="蹲底报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.jtLowestSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.jtLowestSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="冲顶报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.jtHightestSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.jtHightestSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="困人报警:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.stuckSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.stuckSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电梯超速:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.overSpeedSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.overSpeedSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="电动车报警推送:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikePushSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikePushSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="电动车报警识别:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.bikeSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.bikeSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="elevatorForm.deviceSosSwitch">
              <el-col :span="12">
                <el-form-item label="开门行梯:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.runningLadderSosSwitch">
                      <el-radio :label="1">开启报警</el-radio>
                      <el-radio :label="0">关闭报警</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.runningLadderSosSwitch === 1 ? '开启报警' : '关闭报警' }}</template>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="elevatorForm.deviceSosSwitch"
              >
                <el-form-item label="阻梯功能:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.haveBanClosed">
                      <el-radio :label="1">启用</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.haveBanClosed === 1 ? '启用' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <el-col
                :span="12"
                v-if="elevatorForm.deviceSosSwitch"
              >
                <el-form-item label="运行日志:">
                  <template v-if="type !== 'check'">
                    <el-radio-group v-model="elevatorForm.deviceSosSwitch.txtSwitch">
                      <el-radio :label="1">启用</el-radio>
                      <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                  </template>
                  <template v-else>{{elevatorForm.deviceSosSwitch.txtSwitch === 1 ? '启用' : '关闭' }}</template>
                </el-form-item>
              </el-col>
              <!-- <el-col :span="12">
                <el-form-item label="速度选项:">
                  <el-select
                    v-model="elevatorForm.speedOption"
                    class="wid90"
                    filterable
                    clearable
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in speedOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="Number(item.value)"
                    />
                  </el-select>
                </el-form-item>
              </el-col> -->
              <el-col
                :span="12"
                v-if="false"
              >
                <el-form-item label="急停报警:">
                  <el-radio-group v-model="elevatorForm.deviceSosSwitch.jtSosSwitch">
                    <el-radio :label="1">开启报警</el-radio>
                    <el-radio :label="0">关闭报警</el-radio>
                  </el-radio-group>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane
          label="电梯数字终端报警"
          name="sixth"
        >
          <div
            class="searchBox scrollElement"
            style="height: 50vh;overflow-y: scroll;"
          >
            <template v-if='elevatorForm.terminalType === 1'>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="检修:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.maintenanceSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.maintenanceSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.maintenanceSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.maintenanceSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.maintenanceSosSwitch,'maintenanceSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="安全回路:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.safetyCircuitSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.safetyCircuitSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.safetyCircuitSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.safetyCircuitSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.safetyCircuitSosSwitch,'safetyCircuitSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="上限位:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.limitASosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.limitASosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.limitASosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.limitASosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.limitASosSwitch,'limitASosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="轿门回路:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.doorLookLoopSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.doorLookLoopSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.doorLookLoopSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.doorLookLoopSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.doorLookLoopSosSwitch,'doorLookLoopSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="下限位:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.lowerLimitPositionSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.lowerLimitPositionSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.lowerLimitPositionSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.lowerLimitPositionSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.lowerLimitPositionSosSwitch,'lowerLimitPositionSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="抱闸线圈:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.brakeCoilSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.brakeCoilSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.brakeCoilSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.brakeCoilSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.brakeCoilSosSwitch,'brakeCoilSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="锁梯:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.lockTheLadderSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.lockTheLadderSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.lockTheLadderSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.lockTheLadderSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.lockTheLadderSosSwitch,'lockTheLadderSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="厅门回路:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.hallDoorCircuitSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.hallDoorCircuitSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.maintenanceSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.hallDoorCircuitSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.hallDoorCircuitSosSwitch,'hallDoorCircuitSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="24">
                  <el-form-item class="customItem">
                    <template v-if="type !== 'check'">
                      <el-input
                        style="width:150px;"
                        v-model="elevatorForm.terminalSosName"
                        :disabled="type === 'check'"
                        placeholder="请输入"
                        @blur="terminalSosNameChange"
                      />
                      <el-select
                        v-model="elevatorForm.terminalSosType"
                        :disabled="type === 'check' || (elevatorForm.terminalSosName && elevatorForm.terminalSosName === '')"
                        style="width:150px;margin: 0 auto"
                        class="custom"
                        placeholder="请选择"
                      >
                        <el-option
                          label="闭合"
                          :value="0"
                        />
                        <el-option
                          label="动作"
                          :value="1"
                        />
                      </el-select>
                      <el-radio-group
                        :disabled="!elevatorForm.terminalSosName || !elevatorForm.sosSwitchStatus.customizeSosSwitch"
                        v-model="elevatorForm.terminalSosSwitch.customizeSosSwitch"
                        style="margin-right: 20px"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                      <el-checkbox
                        v-model="elevatorForm.sosSwitchStatus.customizeSosSwitch"
                        :disabled="type === 'check' || !elevatorForm.terminalSosName"
                      >是否启用</el-checkbox>
                    </template>
                    <template v-else>
                      <span
                        style="margin-right: 20px"
                        v-if="elevatorForm.terminalSosName"
                      >
                        {{ `${elevatorForm.terminalSosName}:` }}
                      </span>
                      <span
                        style="margin-right: 20px"
                        v-if="elevatorForm.terminalSosName"
                      >
                        {{ elevatorForm.terminalSosSwitch.customizeSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                      <el-checkbox
                        v-if="elevatorForm.terminalSosName"
                        v-model="elevatorForm.sosSwitchStatus.customizeSosSwitch"
                        disabled
                      >是否启用</el-checkbox>
                    </template>
                  </el-form-item>
                </el-col>
              </el-row>
            </template> -->
            <!-- c2 -->
            <!-- <template v-else>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="锁梯:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.lockTheLadderSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.lockTheLadderSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.lockTheLadderSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.lockTheLadderSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.lockTheLadderSosSwitch,'lockTheLadderSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="检修:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.maintenanceSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.maintenanceSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.maintenanceSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.maintenanceSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.maintenanceSosSwitch,'maintenanceSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="安全回路:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.safetyCircuitSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.safetyCircuitSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.safetyCircuitSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.safetyCircuitSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.safetyCircuitSosSwitch,'safetyCircuitSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="抱闸线圈:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.brakeCoilSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.brakeCoilSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.brakeCoilSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.brakeCoilSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.brakeCoilSosSwitch,'brakeCoilSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="轿门回路:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.doorLookLoopSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.doorLookLoopSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.doorLookLoopSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.doorLookLoopSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.doorLookLoopSosSwitch,'doorLookLoopSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="厅门回路:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.hallDoorCircuitSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.hallDoorCircuitSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.hallDoorCircuitSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.hallDoorCircuitSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.hallDoorCircuitSosSwitch,'hallDoorCircuitSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="上限位:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.limitASosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.limitASosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.limitASosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.limitASosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.limitASosSwitch,'limitASosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="下限位:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.lowerLimitPositionSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.lowerLimitPositionSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.lowerLimitPositionSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.lowerLimitPositionSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.lowerLimitPositionSosSwitch,'lowerLimitPositionSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="开门限位:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.openTheDoorLimitSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.openTheDoorLimitSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.openTheDoorLimitSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.openTheDoorLimitSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.openTheDoorLimitSosSwitch,'openTheDoorLimitSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="关门限位:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.closeTheDoorLimitSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.closeTheDoorLimitSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.closeTheDoorLimitSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.closeTheDoorLimitSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.closeTheDoorLimitSosSwitch,'closeTheDoorLimitSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="上平层:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.onTheBedSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.onTheBedSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.onTheBedSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.onTheBedSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.onTheBedSosSwitch,'onTheBedSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="下平层:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.underTheBedSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.underTheBedSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.underTheBedSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.underTheBedSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.underTheBedSosSwitch,'underTheBedSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="上行减速:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.upsideToSlowDownSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.upsideToSlowDownSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.upsideToSlowDownSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.upsideToSlowDownSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.upsideToSlowDownSosSwitch,'upsideToSlowDownSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="下行减速:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.downwardDecelerationSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.downwardDecelerationSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.downwardDecelerationSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.downwardDecelerationSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.downwardDecelerationSosSwitch,'downwardDecelerationSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row v-if="elevatorForm.terminalSosSwitch">
                <el-col :span="12">
                  <el-form-item
                    label="意外移动:"
                    label-width="100px"
                  >
                    <template v-if="type !== 'check'">
                      <el-radio-group
                        v-model="elevatorForm.terminalSosSwitch.surpriseMoveSosSwitch"
                        style="margin-right: 20px"
                        :disabled="!elevatorForm.sosSwitchStatus.surpriseMoveSosSwitch"
                      >
                        <el-radio :label="1">开启报警</el-radio>
                        <el-radio :label="0">关闭报警</el-radio>
                      </el-radio-group>
                    </template>
                    <template v-else>
                      <span style="margin-right: 20px">
                        {{ elevatorForm.terminalSosSwitch.surpriseMoveSosSwitch === 1 ? '开启报警' : '关闭报警' }}
                      </span>
                    </template>
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.surpriseMoveSosSwitch"
                      :disabled="type === 'check'"
                      @change="changeSwitch(elevatorForm.sosSwitchStatus.surpriseMoveSosSwitch,'surpriseMoveSosSwitch')"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="人体感应:"
                    label-width="100px"
                  >
                    <el-checkbox
                      v-model="elevatorForm.sosSwitchStatus.humanBodyInductionSosSwitch"
                      :style="type === 'check' ? 'margin-left: 81px;':'margin-left: 210px;'"
                      :disabled="type === 'check'"
                    >是否启用</el-checkbox>
                  </el-form-item>
                </el-col>
              </el-row>
            </template>
          </div>
        </el-tab-pane> -->
      </el-tabs>
    </el-form>
    <SelectPerson
      v-if="selectPersonVisible"
      ref="selectElevator"
      :unitName="unitName"
      :initIds="initIds"
      :unitType="unitType"
      :orgId="orgId"
      @transferElevator="selectOtherResult"
    />

    <el-dialog
      title="选择地址"
      :visible.sync="addressVisible"
      :close-on-click-modal="false"
      append-to-body
      width="960px"
      top="20vh"
      center
      @close="closeAddress"
    >
      <SelectAddress
        v-if="selectAddressVisible"
        ref="SelectAddress"
        :lnglat="elevatorForm.lnglat"
        @clickAddress="clickAddress"
      />
      <div
        slot="footer"
        class="dialog-footer"
        style="text-align: center"
      >
        <el-button
          type="primary"
          @click="transfeRaddress"
        >确定</el-button>
        <el-button
          type="indo"
          @click="closeAddress"
        >取消</el-button>
      </div>
    </el-dialog>
    <div
      slot="footer"
      class="dialog-footer"
      style="text-align: center"
    >
      <el-button
        type="primary"
        v-if="type !== 'check'"
        @click="confirmForm"
      >确定</el-button>
      <el-button
        type="indo"
        @click="closeDialog"
      >取消</el-button>
    </div>
    <!-- </div> -->
  </el-dialog>
</template>

<script>
import FileUpload from '@/components/FileUpload'
import SelectPerson from './selectPerson'
import SelectAddress from './selectAddress'

export default {
  components: {
    FileUpload,
    SelectPerson,
    SelectAddress,
  },
  props: {
    initData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    var validateZMSZ = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^[a-zA-Z0-9]{2,20}$/,
        '只可输入长度为2-20位的大小写字母',
        callback
      )
    }
    var validateZMSZ420 = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^[a-zA-Z0-9]{4,20}$/,
        '只可输入长度为4-20位的大小写字母',
        callback
      )
    }
    var validateSZ216 = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^[0-9]{2,16}$/,
        '只可输入长度为2-16位的整数',
        callback
      )
    }
    var validateZLCSZ14 = (rule, value, callback) => {
      inputFloor(
        rule,
        value,
        true,
        /^[0-9]{1,4}$/,
        '只可输入长度为1-4位的整数',
        callback
      )
    }
    var validateSZ14 = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^[0-9]{1,4}$/,
        '只可输入长度为1-4位的整数',
        callback
      )
    }
    var validateSZ15 = (rule, value, callback) => {
      inputlc(rule, value, callback)
    }
    var validateSZ13 = (rule, value, callback) => {
      inputsd(rule, value, callback)
    }
    var validateMaxSpeed = (rule, value, callback) => {
      inputMaxSpeed(rule, value, callback)
    }
    var validateDoorWidth = (rule, value, callback) => {
      inputApp(
        rule,
        value,
        false,
        /^\d*\.{0,1}\d{0,1}$/,
        '只可输入整数或保留小数一位',
        callback
      )
    }
    // var valimaxSpeedSZ13 = (rule, value, callback) => {
    //   inputSpeed(rule, value, callback)
    // }
    // var validateZMSZ420 = (rule, value, callback) => {
    //   inputApp(
    //     rule,
    //     value,
    //     false,
    //     /^[a-zA-Z0-9]{4,20}$/,
    //     '只可输入长度为4-20位的大小写字母，特殊符号',
    //     callback
    //   )
    // }
    // var validateSZ216 = (rule, value, callback) => {
    //   inputApp(
    //     rule,
    //     value,
    //     false,
    //     /^[0-9]{2,16}$/,
    //     '只可输入长度为2-16位的整数',
    //     callback
    //   )
    // }
    // var validateZLCSZ14 = (rule, value, callback) => {
    //   inputFloor(
    //     rule,
    //     value,
    //     true,
    //     /^[0-9]{1,4}$/,
    //     '只可输入长度为1-4位的整数',
    //     callback
    //   )
    // }
    // var validateSZ14 = (rule, value, callback) => {
    //   inputApp(
    //     rule,
    //     value,
    //     false,
    //     /^[0-9]{1,4}$/,
    //     '只可输入长度为1-4位的整数',
    //     callback
    //   )
    // }
    // var validateSZ15 = (rule, value, callback) => {
    //   inputlc(rule, value, callback)
    // }
    // var validateSZ13 = (rule, value, callback) => {
    //   inputsd(rule, value, callback)
    // }
    // var validateMaxSpeed = (rule, value, callback) => {
    //   inputMaxSpeed(rule, value, callback)
    // }
    // // var valimaxSpeedSZ13 = (rule, value, callback) => {
    // //   inputSpeed(rule, value, callback)
    // // }
    // var validateSZ715 = (rule, value, callback) => {
    //   inputIp(rule, value, callback)
    // }

    return {
      roleId:'',
      loading: true,
      visible: false,
      addressVisible: false, // 安装位置弹框
      type: '', // 电梯新增/修改
      title: '', // 弹窗标题
      activeName: 'first', // 默认第一屏
      elevatorForm: {
        maxSpeed: 2,
        smsSos:0,
        headValue: 0.50,
        openValue: 0.50,
        bikeValue: 0.50,
        staticHeadProb:0.50,
      }, // 电梯表单信息
      // 地区选择器
      props: {
        label: 'name',
        value: 'areaCode',
        checkStrictly: true,
      },
      propertyDisabled: false,
      plotArr: [], // 所属小区
      eleBrand: [], // 电梯品牌
      riskLevelList: [], // 电梯风险等级
      wbUnit: [], // 维保单位,
      wyUnit: [], // 物业单位,
      jyUnit: [], // 救援单位
      azUnit: [], // 安装单位
      bxUnit: [], // 保险单位
      zzUnit: [], // 制造单位
      cqUnit: [], // 产权单位
      djUnit: [], // 登记机关
      jyanUnit: [], // 检验单位
      csUnit: [], // 厂商单位
      jdUnit: [], // 街道单位

      eleType: [], // 电梯类型
      genneration: [], // 生产方式
      useState: [], // 使用状态
      usePlace: [], // 使用产所分类
      eleVarieties: [], // 电梯品种
      eleControlMode: [], // 电梯控制方式
      maintenReminderTime: [], // 维保提醒时间
      maintenLevel: [], // 维保等级
      speedOptions: [], // 速度选项

      insuranceUnitList: [],
      selectAddressVisible: false, // 选择位置弹框
      selectPersonVisible: false, // 选择人员弹框
      orgId: '', // 单位id
      unitName: '', // 单位name
      personType: ' ', // 选择类型
      // initArr: [], // 选择人员集合
      unitType: '', // 选择人员类型
      maintainArr: [],
      propertyArr: [],
      emergencyArr: [],
      insuranceArr: [],
      initIds: [], // 选择人员id集合
      //表单
      elevatorFormRules:{
        name: [
          {
            required: true,
            message: '电梯名称不能为空',
            trigger: 'blur',
          },
        ],
        rescueCode: [
          {
            required: true,
            message: '救援识别码不能为空',
             trigger: 'blur',
           },
        ],
        plotId: [
          { required: true, message: '所属小区不能为空', trigger: 'blur' },
        ],
        code: [
          { required: true, message: '注册代码不能为空', trigger: 'blur' },
          { required: true, message: '注册代码长度不多于50个且少于2个', trigger: 'blur', min: 2, max: 50 },
          { required: true, message: '注册代码长度不多于50个且少于2个', trigger: 'change', min: 2, max: 50 },
        ],
        factoryNumber: [
          { required: true, message: '出厂编码不能为空', trigger: 'blur' },
        ],

        floorDong: [
          { required: true, message: '楼栋不能为空', trigger: 'blur' },
          { required: true, message: '楼栋字符长度不多于20个', trigger: 'blur', max: 20 },
          { required: true, message: '楼栋字符长度不多于20个', trigger: 'change', max: 20 }
        ],
        eleUnit: [
          { required: true, message: '单元不能为空', trigger: 'blur' },
          { required: true, message: '单元字符长度不多于20个', trigger: 'blur', max: 20 },
          { required: true, message: '单元字符长度不多于20个', trigger: 'change', max: 20 }
        ],
        eleCode: [
          { required: true, message: '电梯号不能为空', trigger: 'blur' },
          { required: true, message: '电梯号字符长度不多于20个', trigger: 'blur', max: 20 },
          { required: true, message: '电梯号字符长度不多于20个', trigger: 'change', max: 20 },
        ],

        eleAddress: [
          { required: true, message: '安装位置不能为空', trigger: 'blur' },
        ],
        maintainUnitId: [
          { required: true, message: '维保单位不能为空', trigger: 'blur' },
        ],
        propertyUnitId: [
           { required: true, message: '物业单位不能为空', trigger: 'blur' },
        ],
         emergencyRescueUnitId: [
          { required: true, message: '救援单位不能为空', trigger: 'blur' },
        ],
        registrationAuthorityId: [
          { required: true, message: '登记机关不能为空', trigger: 'blur' },
        ],
        insuranceUnitId: [
          { required: true, message: '保险单位不能为空', trigger: 'blur' },
        ],
        maintainUserIds: [
          { required: true, message: '维保人员不能为空', trigger: 'blur' },
        ],
        propertyUnitUserIds: [
          { required: true, message: '物业人员不能为空', trigger: 'blur' },
        ],
        emergencyRescueUserIds: [
          { required: true, message: '救援人员不能为空', trigger: 'blur' },
        ],
        insuranceUnitUserIds: [
           { required: true, message: '保险人员不能为空', trigger: 'blur' },
        ],
        brandId: [
          { required: true, message: '电梯品牌不能为空', trigger: 'blur' },
        ],
        floorHeight: [
          { required: true, message: '电梯停靠站数不能为空', trigger: 'blur' },
        ],
        underFloor: [
          { required: true, message: '地下楼层数不能为空', trigger: 'blur' },
          { required: true, validator: this.changeUnderFloor, trigger: 'blur' }
        ],
        elePlaceType: [
          { required: true, message: '使用场所不能为空', trigger: 'blur' },
        ],
        overSpeed: [
          { required: true, message: '报警阈值不能为空', trigger: 'blur' },
          { validator: this.changeOverSpeed, trigger: 'blur' }
        ],
        maxSpeed: [
          { required: true, message: '最大速度不能为空', trigger: 'blur' },
        ],
        maxSpeedA: [
          { required: true, message: '报警阈值(加速度)不能为空', trigger: 'blur' },
        ],
        doorWidth: [
          { required: false, validator: validateDoorWidth, trigger: 'blur' },
        ]
      },
      // elevatorFormRules: {
      //   // 电梯表单规则elevatorForm
      //   name: [
      //     {
      //       required: true,
      //       message: '仅能输入中文，大小写字母，特殊符号且长度为2至50',
      //       trigger: 'blur',
      //       min: 2,
      //       max: 50,
      //     },
      //   ],
      //   rescueCode: [
      //     {
      //       required: true,
      //       message: '仅能输入中文，大小写字母，特殊符号且长度为2至20',
      //       trigger: 'blur',
      //       min: 2,
      //       max: 20,
      //     },
      //   ],
      //   plotId: [
      //     { required: true, message: '所属小区不能为空', trigger: 'blur' },
      //   ],
      //   code: [
      //     {
      //       required: true,
      //       message: '仅能输入中文，大小写字母，特殊符号且长度为2至50',
      //       trigger: 'blur',
      //       min: 2,
      //       max: 50,
      //     },
      //   ],
      //   factoryNumber: [
      //     {
      //       required: true,
      //       message: '仅能输入中文，大小写字母，特殊符号且长度为4至20',
      //       trigger: 'blur',
      //       min: 4,
      //       max: 20
      //     },
      //   ],
      //   deviceCode: [{ validator: validateSZ216, trigger: 'blur' }],
      //   terminalIcd: [{ validator: validateSZ216, trigger: 'blur' }],
      //   eleAddress: [
      //     { required: true, message: '安装位置不能为空', trigger: 'blur' },
      //   ],
      //   // areaCodeArr: [
      //   //   { required: true, message: '请选择所属区域', trigger: 'blur' },
      //   // ],
      //   propertyUnitInternalNumber: [
      //     {
      //       message: '仅能输入中文，大小写字母，特殊符号且长度为4至40',
      //       trigger: 'blur',
      //       min: 4,
      //       max: 20,
      //     },
      //   ],
      //   registeredCode: [{ validator: validateZMSZ420, trigger: 'blur' }],
      //   maintainUnitId: [
      //     { required: true, message: '维保单位不能为空', trigger: 'blur' },
      //   ],
      //   propertyUnitId: [
      //     { required: true, message: '物业单位不能为空', trigger: 'blur' },
      //   ],
      //   emergencyRescueUnitId: [
      //     { required: true, message: '救援单位不能为空', trigger: 'blur' },
      //   ],
      //   registrationAuthorityId: [
      //     { required: true, message: '登记机关不能为空', trigger: 'blur' },
      //   ],
      //   insuranceUnitId: [
      //     { required: true, message: '保险单位不能为空', trigger: 'blur' },
      //   ],
      //   maintainUserIds: [
      //     { required: true, message: '维保人员不能为空', trigger: 'blur' },
      //   ],
      //   propertyUnitUserIds: [
      //     { required: true, message: '物业人员不能为空', trigger: 'blur' },
      //   ],
      //   emergencyRescueUserIds: [
      //     { required: true, message: '救援人员不能为空', trigger: 'blur' },
      //   ],
      //   insuranceUnitUserIds: [
      //     { required: true, message: '保险人员不能为空', trigger: 'blur' },
      //   ],
      //   // elevatorUseSign: [
      //   //   {
      //   //     required: true,
      //   //     message: '电梯使用标志不能为空',
      //   //     trigger: 'change',
      //   //   },
      //   // ],
      //   manufacturingCode: [{ validator: validateZMSZ420, trigger: 'blur' }],
      //   eleModel: [
      //     {
      //       message: '仅能输入长度为4至40的中文，大小写字母，特殊符号',
      //       trigger: 'blur',
      //       min: 4,
      //       max: 20,
      //     },
      //   ],
      //   brandId: [
      //     { required: true, message: '电梯品牌不能为空', trigger: 'blur' },
      //   ],
      //   floorHeight: [
      //     { required: true, validator: validateZLCSZ14, trigger: 'blur' },
      //   ],
      //   underFloor: [
      //     // { validator: validateSZ14, trigger: 'blur' },
      //     { required: true, validator: this.changeUnderFloor, trigger: 'blur' }
      //   ],
      //   elePlaceType: [
      //     { required: true, message: '使用场所不能为空', trigger: 'blur' },
      //   ],
      //   standNumber: [{ validator: validateSZ14, trigger: 'blur' }],
      //   doorNumber: [{ validator: validateSZ14, trigger: 'blur' }],
      //   atedLoad: [{ validator: validateSZ15, trigger: 'blur' }],
      //   overSpeed: [
      //     { required: true, message: '超速报警阈值不能为空', trigger: 'blur' },
      //     { validator: this.changeOverSpeed, trigger: 'blur' },
      //   ],
      //   maxSpeed: [
      //     { required: true, validator: validateMaxSpeed, trigger: 'blur' },
      //   ],
      //   maxSpeedA: [{ required: true, validator: validateSZ13, trigger: 'blur' }],
      //   deviceIp: [{ validator: validateSZ715, trigger: 'blur' }],
      //   deviceGateway: [{ validator: validateSZ715, trigger: 'blur' }],
      //   deviceDns: [{ validator: this.changeDNS, trigger: 'blur' }],
      // },
      deviceSosSwitch: {
        sosSwitch: 1, // 主动报警
        aiIdentifySwitch: 1, // AI识别
        audioSosSwitch: 1, // 语音报警
        safetyCircuitSosSwitch: 1, // 安全回路报警
        doorOpenSosSwitch: 0, // 关门故障
        overFloorSosSwitch: 1, // 开锁区域外停梯
        jtLowestSosSwitch: 1, // 蹲底报警
        jtHightestSosSwitch: 1, // 冲顶报警
        stuckSwitch: 1, // 困人报警
        overSpeedSosSwitch: 1, // 电梯超速
        bikePushSosSwitch: 1, // 电动车报警推送
        bikeSosSwitch: 1, // 电动车报警识别
        haveBanClosed: 0, // 阻梯功能
        txtSwitch: 0, // 运行日志
        jtSosSwitch: 1, // 急停报警
        runningLadderSosSwitch: 0, // 开门行梯
        portraitBlur:0,
        headBorder:0,
        bikeBorder:0,
        doorBorder:0
      },
      // terminalSosSwitch: {
      //   lockTheLadderSosSwitch: 1, // 锁梯
      //   maintenanceSosSwitch: 1, // 检修
      //   safetyCircuitSosSwitch: 1, // 安全回路
      //   brakeCoilSosSwitch: 1, // 抱闸线圈
      //   doorLookLoopSosSwitch: 1, // 轿门回路
      //   hallDoorCircuitSosSwitch: 1, // 厅门回路
      //   limitASosSwitch: 1, // 上限位
      //   lowerLimitPositionSosSwitch: 1, // 下限位
      //   openTheDoorLimitSosSwitch: 1, // 开门限位
      //   closeTheDoorLimitSosSwitch: 1, // 关门限位
      //   onTheBedSosSwitch: 1, // 上平层
      //   underTheBedSosSwitch: 1, // 下平层
      //   upsideToSlowDownSosSwitch: 1, // 上行减速
      //   downwardDecelerationSosSwitch: 1, // 下行减速
      //   surpriseMoveSosSwitch: 1, // 意外移动
      //   humanBodyInductionSosSwitch: 1, // 人体感应
      //   customizeSosSwitch: 0, // 自定义字段
      // },
      // sosSwitchStatus: {
      //   lockTheLadderSosSwitch: true,
      //   maintenanceSosSwitch: true,
      //   safetyCircuitSosSwitch: true,
      //   brakeCoilSosSwitch: true,
      //   doorLookLoopSosSwitch: true,
      //   hallDoorCircuitSosSwitch: true,
      //   limitASosSwitch: true,
      //   lowerLimitPositionSosSwitch: true,
      //   openTheDoorLimitSosSwitch: true,
      //   closeTheDoorLimitSosSwitch: true,
      //   onTheBedSosSwitch: true,
      //   underTheBedSosSwitch: true,
      //   upsideToSlowDownSosSwitch: true,
      //   downwardDecelerationSosSwitch: true,
      //   surpriseMoveSosSwitch: true,
      //   humanBodyInductionSosSwitch: true,
      //   customizeSosSwitch: false, // 自定义字段
      // },
      addBrandCallback: null, // 弹窗回调
      trans: false,
      editSimTime: false, // sim卡时间
      initDeviceCode: null, // 初始物联网设备
      initFloorHeight: null, // 初始楼层
      initUnderFloor: null, // 初始地下楼层
      initSpeedOption: null, // 初始速度选项
      initTxtSwitch: null, // 初始运行日志状态

      DNSList: [], // dns
      adminInfo: JSON.parse(sessionStorage.getItem('adminInfo'))
    }
  },

  mounted() {
    const adminInfo = JSON.parse(sessionStorage.getItem('adminInfo'))
    this.roleId = adminInfo.roleId
    // this.getUnit()
    // this.getBrand()
    this.getNotice('公告类型', [
      'ELEVATOR_TYPE',
      'GENNERATION',
      'USE_STATE',
      'USE_PLACE',
      'ELEVATOR_VARIETIES',
      'ELEVATOR_CONTROL_MODE',
      'MAINTEN_REMINDER_TIME',
      'MAINTENANCE_LEVEL',
      'SPEED_OPTIONS',
      'ELE_RISK_LEVEL',
      'DNS_SERVER'
    ])
  },

  methods: {
    changeDNS(rule, value, callback) {
      if (value && !/^(\d{1,2}|1\d\d|2[0-3][0-3]|23[0-3])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(value)) {
        callback(new Error('请输入正确的DNS参数'))
      }
      callback()
    },
    changeOverSpeed(rule, value, callback) {
      if (value < Number(this.elevatorForm.maxSpeed) * 1.1) {
        callback(new Error('超速报警阀值最小不能低于最大速度的1.1倍'))
      }
      callback()
    },

    changeUnderFloor(rule, value, callback) {
      if (value !== '') {
        if (/^[0-9]{1,4}$/.test(value)) {
          if (value >= Number(this.elevatorForm.floorHeight)) {
            callback(new Error('地下楼层数必须小于电梯停靠站数'))
          } else {
            callback()
          }
        } else {
          callback(new Error('只可输入输入0-9999的整数'))
        }
      } else {
        callback(new Error('地下楼层数不能为空'))
      }
    },
    // 新增/修改，电梯信息，回调
    init(type, data, callback) {
      // this.title = type === 'add' ? '新增电梯详情' : '编辑电梯详情'
      this.title = '电梯详情'
      this.type = type
      this.addBrandCallback = callback
      this.propertyDisabled = false
      this.visible = true
      this.loading = false
      this.activeName = 'first'
      this.propertyDisabled = false
      this.elevatorForm =
        type === 'add'
          ? {
            speedOption: 2,
            eleState: 'IN_USE',
            maxSpeed: 2,
            overSpeed: 2.20,
            smsSos: 0,
            headValue: 0.50,
            openValue: 0.50,
            bikeValue: 0.50,
            staticHeadProb:0.50,
          }
          : data
          console.log(data,'data');

      // 保险单位
      if (type !== 'add') {
        this.elevatorForm.insuranceUnitId = data.insuranceUnitId
          ? data.insuranceUnitId
          : 100
        this.elevatorForm.insuranceUnitUserIds =
          data.insuranceUnitUserIds.length !== 0
            ? data.insuranceUnitUserIds
            : [100]
        this.elevatorForm.insuranceUnitUserNames = data.insuranceUnitUserNames
          ? data.insuranceUnitUserNames
          : '暂无保险人员'


        this.$set(this.elevatorForm, 'headValue', data.headValue ? data.headValue : 0 )
        this.$set(this.elevatorForm, 'openValue', data.openValue ? data.openValue : 0 )
        this.$set(this.elevatorForm, 'bikeValue', data.bikeValue ? data.bikeValue : 0 )
        this.$set(this.elevatorForm, 'staticHeadProb', data.staticHeadProb ? data.staticHeadProb : 0 )
      }

      // 安装位置经纬度
      this.elevatorForm.lnglat =
        type === 'add' ? [] : [data.longitude, data.latitude]
      if (type === 'add') {
        // 球机报警开关
        this.$set(this.elevatorForm, 'deviceSosSwitch', this.deviceSosSwitch)
        // 盒子报警开关
        // this.$set(
        //   this.elevatorForm,
        //   'terminalSosSwitch',
        //   this.terminalSosSwitch
        // )
        // 盒子报警显隐
        // this.$set(this.elevatorForm, 'sosSwitchStatus', this.sosSwitchStatus)
        // 盒子型号
        // this.$set(this.elevatorForm, 'terminalType', 1)
        // 盒子动作
        // this.$set(this.elevatorForm, 'terminalSosType', 1)
      }
      if (this.elevatorForm.deviceSosSwitch.length === 0) {
        this.$set(this.elevatorForm, 'deviceSosSwitch', this.deviceSosSwitch)
      }
      // if (this.elevatorForm.terminalSosSwitch.length === 0) {
      //   this.$set(
      //     this.elevatorForm,
      //     'terminalSosSwitch',
      //     this.terminalSosSwitch
      //   )
      // }
      // if (this.elevatorForm.sosSwitchStatus.length === 0) {
      //   this.$set(this.elevatorForm, 'sosSwitchStatus', this.sosSwitchStatus)
      // }
      // // 盒子型号
      // if (!this.elevatorForm.terminalType) {
      //   this.$set(this.elevatorForm, 'terminalType', 1)
      //   // 盒子动作
      //   if (!this.elevatorForm.terminalSosName) {
      //     this.$set(this.elevatorForm, 'terminalSosType', 1)
      //     this.$set(
      //       this.elevatorForm,
      //       'terminalSosSwitch',
      //       this.terminalSosSwitch
      //     )
      //   }
      // }

      // 记录初始状态
      this.initDeviceCode =
        this.initData.deviceCode || this.initData.deviceCode === ''
          ? this.initData.deviceCode
          : null
      this.initFloorHeight = this.initData.floorHeight
        ? this.initData.floorHeight
        : null
      this.initUnderFloor =
        this.initData.underFloor || this.initData.underFloor === 0
          ? this.initData.underFloor
          : null
      this.initSpeedOption = this.initData.speedOption
        ? this.initData.speedOption
        : null
      this.initTxtSwitch =
        this.initData.deviceSosSwitch && this.initData.deviceSosSwitch.txtSwitch
          ? this.initData.deviceSosSwitch.txtSwitch
          : 0
      this.$nextTick(() => {
        this.getUnit()
        this.getPlot()
        this.getBrand()
        if (data.plotId) {
          this.getPlotInfo(data.plotId)
        }
      })
    },

    changePlot (val) {
      this.getPlotInfo(val)
      this.changeUnit('property')
    },
    getPlotInfo (id) {
      const dataObj = {
        current: 1,
        size: 99,
        id: id
      }
      this.$http.post("/api/ele/web/plot/list", dataObj).then((res) => {
        if (res.data.success) {
          const { data: { data }} = res;
          this.$set(this.elevatorForm, 'propertyUnitId', data.records[0].propertyUnitId)
          this.propertyDisabled = true
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    // // 盒子自定义名称改变
    // terminalSosNameChange() {
    //   if (this.elevatorForm.terminalSosName === '') {
    //     // 盒子动作
    //     this.$set(this.elevatorForm, 'terminalSosType', 1)
    //     this.elevatorForm.terminalSosSwitch.customizeSosSwitch = 0
    //     this.elevatorForm.sosSwitchStatus.customizeSosSwitch = false
    //   }
    // },

    // // 关闭启用触发
    // changeSwitch(data, type) {
    //   if (!data) {
    //     this.elevatorForm.terminalSosSwitch[type] = 0
    //   }
    // },
    changeUnit(type) {
      if (type === 'maintain') {
        this.elevatorForm.maintainUserIds = []
        this.elevatorForm.maintainUserNames = ''
      }
      if (type === 'property') {
        this.elevatorForm.propertyUnitUserIds = []
        this.elevatorForm.propertyUnitUserNames = ''
      }
      if (type === 'emergency') {
        this.elevatorForm.emergencyRescueUserIds = []
        this.elevatorForm.emergencyRescueUserNames = ''
      }
      if (type === 'insurance') {
        this.elevatorForm.insuranceUnitUserIds = []
        this.elevatorForm.insuranceUnitUserNames = ''
        if (this.elevatorForm.insuranceUnitId === 100) {
          this.elevatorForm.insuranceUnitUserIds = [100]
          this.elevatorForm.insuranceUnitUserNames = '暂无保险人员'
        }
      }
    },
    // 提交弹框
    submit() {
      console.log(this.elevatorForm, 'this.elevatorForm')
      this.$refs.elevatorForm.validate(async (valid) => {
        if (valid) {
          if (this.elevatorForm.insuranceUnitId === 100) {
            this.elevatorForm.insuranceUnitId = null
            this.elevatorForm.insuranceUnitUserIds = []
          }
          const data = JSON.parse(JSON.stringify(this.elevatorForm))
          data.deviceSosSwitch = JSON.stringify(
            this.elevatorForm.deviceSosSwitch
          )
          data.sosSwitchStatus = JSON.stringify(
            this.elevatorForm.sosSwitchStatus
          )
          data.terminalSosSwitch = JSON.stringify(
            this.elevatorForm.terminalSosSwitch
          )
          // data.areaCodeArr = data.areaCode
          // if (data.areaCodeArr) {
          //   if (data.areaCodeArr.length > 0) {
          //     data.areaCodes = JSON.stringify(data.areaCodeArr)
          //     data.areaCode = data.areaCodeArr[data.areaCodeArr.length - 1]
          //   }
          // }

          // sim开卡时间
          if (
            this.elevatorForm.simStartTime &&
            this.elevatorForm.simEndTime &&
            this.editSimTime
          ) {
            if (
              this.elevatorForm.simStartTime.getTime() >=
              this.elevatorForm.simEndTime.getTime()
            ) {
              this.$message.error('sim到期时间必须晚与开卡时间')
              return
            }
          }
          data.simStartTime = this.elevatorForm.simStartTime
            ? this.elevatorForm.simStartTime.getTime()
            : ''
          data.simEndTime = this.elevatorForm.simEndTime
            ? this.elevatorForm.simEndTime.getTime()
            : ''
          const url =
            this.type === 'add'
              ? '/api/ele/web/elevatorInfo/submit'
              : '/api/ele/web/elevatorInfo/update'
          // 楼层修改重启球机
          if (this.type === 'edit') {
            if (
              this.elevatorForm.deviceCode &&
              (this.initDeviceCode !== this.elevatorForm.deviceCode ||
                this.initFloorHeight !== this.elevatorForm.floorHeight ||
                this.initUnderFloor !== this.elevatorForm.underFloor ||
                this.initSpeedOption !== this.elevatorForm.speedOption ||
                this.initTxtSwitch !==
                  this.elevatorForm.deviceSosSwitch.txtSwitch)
            ) {
              const agentData = {
                act: 'device_restart',
                device_id: this.elevatorForm.deviceCode,
              }
              this.socketApi.sendSock(agentData, (e) => {
                if (e.cmd === 'device_restart' && e.status) {
                  this.$message.success('稍后球机自动重启！')
                } else {
                  this.$message.error(e.msg)
                }
              })
            }
          }

          this.$http.post(url, data).then((res) => {
            if (res.data.success) {
              this.$message.success(res.data.msg)
              this.$refs.elevatorForm.resetFields()
              if (this.addBrandCallback) {
                this.addBrandCallback(true)
              }
              this.visible = false
            } else {
              this.$message.warning(res.data.msg)
              if (this.elevatorForm.insuranceUnitId === null) {
                this.elevatorForm.insuranceUnitId = 100
                this.elevatorForm.insuranceUnitUserIds = [100]
              }
            }
            this.editSimTime = false
          })
        } else {
          if (
            !this.elevatorForm.name ||
            !this.elevatorForm.code ||
            !this.elevatorForm.plotId ||
            !this.elevatorForm.factoryNumber ||
            !this.elevatorForm.floorDong ||
            !this.elevatorForm.eleUnit ||
            !this.elevatorForm.eleCode ||
            this.elevatorForm.code.length < 2 ||
            this.elevatorForm.code.length > 50 ||
            this.elevatorForm.floorDong.length > 20 ||
            this.elevatorForm.eleUnit.length > 20 ||
            this.elevatorForm.eleCode.length > 20 ||
            !this.elevatorForm.eleAddress
          ) {
            console.log(this.elevatorForm.floorDong, this.elevatorForm.eleUnit, this.elevatorForm.eleCode);
            this.$message.warning('主要资料表单信息异常，请检查')
          } else if (
            !this.elevatorForm.maintainUnitId ||
            this.elevatorForm.maintainUnitId.length === 0 ||
            !this.elevatorForm.propertyUnitId ||
            this.elevatorForm.propertyUnitId.length === 0 ||
            !this.elevatorForm.emergencyRescueUnitId ||
            this.elevatorForm.emergencyRescueUnitId.length === 0 ||
            !this.elevatorForm.insuranceUnitId ||
            this.elevatorForm.insuranceUnitId.length === 0 ||
            !this.elevatorForm.maintainUserIds ||
            this.elevatorForm.maintainUserIds.length === 0 ||
            !this.elevatorForm.propertyUnitUserIds ||
            this.elevatorForm.propertyUnitUserIds.length === 0 ||
            !this.elevatorForm.emergencyRescueUserIds ||
            this.elevatorForm.emergencyRescueUserIds.length === 0 ||
            !this.elevatorForm.insuranceUnitUserIds ||
            this.elevatorForm.insuranceUnitUserIds.length === 0 ||
            !this.elevatorForm.registrationAuthorityId
          ) {
            this.$message.warning('相关单位表单信息异常，请检查')
          } else if (
            !this.elevatorForm.maxSpeed ||
            !this.elevatorForm.overSpeed ||
            !this.elevatorForm.maxSpeedA ||
            this.elevatorForm.overSpeed < this.elevatorForm.maxSpeed * 1.1 ||
            (this.elevatorForm.deviceDns && !/^(\d{1,2}|1\d\d|2[0-3][0-3]|23[0-3])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/.test(this.elevatorForm.deviceDns)
            )
          ) {
            this.$message.warning('物联网设备相关设置表单信息异常，请检查')
          } else {
            console.log(this.elevatorForm.floorDong, this.elevatorForm.eleUnit, this.elevatorForm.eleCode);
            this.$message.warning('电梯参数表单信息异常，请检查')
          }
        }
      })
    },
    // 确认弹框
    confirmForm() {
      this.submit()
    },
    // 关闭弹框
    closeDialog() {
      this.$refs.elevatorForm.resetFields()
      this.visible = false
    },
    closeAddress() {
      this.selectAddressVisible = false
      this.addressVisible = false
    },
    changeTime() {
      this.editSimTime = true
    },

    // 选择安装位置
    chooseAddress() {
      this.addressVisible = true
      this.selectAddressVisible = true
    },
    // 点击位置
    clickAddress(data) {
      console.log(data, 'clickAddress')
      this.elevatorForm.eleAddress = data.address
      this.elevatorForm.areaCode = data.areaCode
      this.elevatorForm.longitude = data.markersPosition[0]
      this.elevatorForm.latitude = data.markersPosition[1]
      this.elevatorForm.lnglat = [
        data.markersPosition[0],
        data.markersPosition[1],
      ]
      if (this.trans) {
        this.addressVisible = false
        this.selectAddressVisible = false
        this.trans = false
      }
    },
    // 确定地址
    transfeRaddress() {
      this.trans = true
      this.$nextTick(() => {
        this.$refs.SelectAddress.getInfo()
      })
    },
    // 自动算超速预警值
    changemaxSpeed() {
      this.elevatorForm.overSpeed = (this.elevatorForm.maxSpeed * 1.1).toFixed(2)
    },

    // 选择人员
    selectElevator(type, id) {
      // 单位id
      this.orgId = id
      // 单位名称
      let unitArr = []
      // 已选人员集合
      let initArr = []
      this.personType = type
      this.unitType = type
      switch (type) {
        case 'MAINTAIN_UNIT':
          initArr = this.maintainArr
          this.initIds = this.elevatorForm.maintainUserIds ?? []
          unitArr = this.wbUnit

          break

        case 'TENEMENT_UNIT':
          initArr = this.propertyArr
          this.initIds = this.elevatorForm.propertyUnitUserIds ?? []
          unitArr = this.wyUnit
          break

        case 'RESCUE_UNIT':
          initArr = this.emergencyArr
          this.initIds = this.elevatorForm.emergencyRescueUserIds ?? []
          unitArr = this.jyUnit
          break

        case 'INSURANCE_UNIT':
          initArr = this.insurancArr
          this.initIds = this.elevatorForm.insuranceUnitUserIds ?? []
          unitArr = this.bxUnit
          break

        default:
          break
      }

      this.unitName = unitArr.find((item) => item.id === id)
        ? unitArr.find((item) => item.id === id).name
        : ''
      this.selectPersonVisible = true
      this.$nextTick(() => {
        this.$refs.selectElevator.init(initArr, (refresh) => {
          if (refresh) {
          }
        })
      })
    },
    // 选择人员返回
    selectOtherResult(data) {
      console.log(data,'data');
      const nameArr = []
      const idArr = []
      data.map((item) => {
        nameArr.push(`${item.name}(${item.contactWay})`)
        idArr.push(item.id)
      })
      // this.initArr = data
      this.selectPersonVisible = false

      switch (this.personType) {
        case 'MAINTAIN_UNIT':
          this.maintainArr = data
          this.$set(this.elevatorForm, 'maintainUserNames', nameArr.join())
          this.elevatorForm.maintainUserIds = idArr
          break

        case 'TENEMENT_UNIT':
          this.propertyArr = data
          this.$set(this.elevatorForm, 'propertyUnitUserNames', nameArr.join())
          this.elevatorForm.propertyUnitUserIds = idArr
          break

        case 'RESCUE_UNIT':
          this.emergencyArr = data
          this.$set(
            this.elevatorForm,
            'emergencyRescueUserNames',
            nameArr.join()
          )
          this.elevatorForm.emergencyRescueUserIds = idArr
          break

        case 'INSURANCE_UNIT':
          this.insuranceArr = data
          this.$set(this.elevatorForm, 'insuranceUnitUserNames', nameArr.join())
          this.elevatorForm.insuranceUnitUserIds = idArr
          break

        default:
          break
      }
    },

    // 获取各单位数据
    getUnit() {
      //维保单位,物业单位,救援单位,安装单位,保险单位,制造单位,产权单位,登记机关,检验单位,厂商单位
      const arr = [
        'MAINTAIN_UNIT',
        'TENEMENT_UNIT',
        'RESCUE_UNIT',
        'INSTALL_UNIT',
        'INSURANCE_UNIT',
        'MAKE_UNIT',
        'PROPERTY_UNIT',
        'REGISTER_UNIT',
        'CHECK_UNIT',
        'VENDOR_UNIT',
        'STREET_UNIT'
      ]
      const data = {
        current: 1,
        size: 10000,
        user: this.initData.createUser ? this.initData.createUser : '',
      }
      arr.map((item) => {
        data.type = item
        if (item === 'RESCUE_UNIT') {
          data.type = 'RESCUE_UNIT,MAINTAIN_UNIT'
        }
        this.$http.post('/api/system/web/org/list', data).then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            switch (item) {
              case 'MAINTAIN_UNIT':
                this.wbUnit = data.records ?? []
                break
              case 'TENEMENT_UNIT':
                this.wyUnit = data.records ?? []
                break
              case 'RESCUE_UNIT':
                this.jyUnit = data.records ?? []
                break
              case 'INSTALL_UNIT':
                this.azUnit = data.records ?? []
                break
              case 'INSURANCE_UNIT':
                this.bxUnit = data.records ?? []
                this.bxUnit.unshift({
                  id: 100,
                  name: '无保险单位',
                })
                break
              case 'MAKE_UNIT':
                this.zzUnit = data.records ?? []
                break
              case 'PROPERTY_UNIT':
                this.cqUnit = data.records ?? []
                break
              case 'REGISTER_UNIT':
                this.djUnit = data.records ?? []
                break
              case 'CHECK_UNIT':
                this.jyanUnit = data.records ?? []
                break
              case 'VENDOR_UNIT':
                this.csUnit = data.records ?? []
                break
              case 'STREET_UNIT':
                this.jdUnit = data.records ?? []
                break
              default:
                break
            }
          } else {
            this.$message.error(res.data.msg)
          }
        })
      })
    },

    // 所属小区
    getPlot() {
      const data = {
        current: 1,
        size: 10000,
		    isUse: 0,
        user: this.initData.createUser ? this.initData.createUser : '',
      }
      this.$http.post('/api/ele/web/plot/list', data).then((res) => {
        if (res.data.code === 200) {
          const {
            data: { data },
          } = res
          this.plotArr = data.records ?? []
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 电梯品牌
    getBrand() {
      const data = {
        current: 1,
        size: 10000,
      }
      this.$http.post('/api/ele/web/brand/list', data).then((res) => {
        if (res.data.code === 200) {
          const {
            data: { data },
          } = res
          this.eleBrand = data.records ?? []
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    // 获取字典
    getNotice(type, code) {
      this.$http
        .post(`/api/system/web/dict/dictionary?codes=${code}`)
        .then((res) => {
          if (res.data.success) {
            const {
              data: { data },
            } = res
            this.eleType = data.ELEVATOR_TYPE
            this.genneration = data.GENNERATION
            this.useState = data.USE_STATE
            this.usePlace = data.USE_PLACE
            this.eleVarieties = data.ELEVATOR_VARIETIES
            this.eleControlMode = data.ELEVATOR_CONTROL_MODE
            this.maintenReminderTime = data.MAINTEN_REMINDER_TIME
            this.maintenLevel = data.MAINTENANCE_LEVEL
            this.speedOptions = data.SPEED_OPTIONS
            this.riskLevelList = data.ELE_RISK_LEVEL
            this.DNSList = data.DNS_SERVER
          } else {
            this.$message.error(res.data.msg)
          }
        })
    },
  },
}
</script>
<style lang="scss" scoped>
.wid90 {
  width: 90%;
}
.wid96 {
  width: 96%;
}
.textarea {
  /deep/.el-textarea__inner {
    color: white !important;
    border: 1px solid #04e3ef !important;
    background-color: #1282a5 !important;
  }
}
/deep/.el-tabs__item,
.el-radio,
.el-checkbox {
  color: #fff;
}
.terminalType /deep/.el-select .el-input {
  width: 80px;
}
.custom /deep/.el-input {
  width: 120px;
  margin: 0 20px;
}
.customItem {
  /deep/.el-form-item__content {
    margin-left: 50px !important;
  }
}
/deep/.el-radio__input.is-disabled.is-checked .el-radio__inner {
  border-color: #409EFF !important;
  background: #409EFF !important;
}
/deep/.is-checked .el-radio__label{
  color: #409EFF !important;
}
</style>
