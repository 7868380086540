<template>
	<div>
		<el-descriptions class="margin-top" :column="3" border>
			<el-descriptions-item>
				<template slot="label">
					电梯注册代码
				</template>
				{{dioData.eleCode}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					电梯名称
				</template>
				{{dioData.eleName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					处理状态
				</template>
				{{dioData.statusName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					物业单位
				</template>
				{{dioData.propertyUnitName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					维保单位
				</template>
				{{dioData.maintainUnitName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					故障来源
				</template>
				{{dioData.sourceName}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					故障报告时间
				</template>
				{{dioData.failureTime}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					电梯停运时间
				</template>
				{{dioData.outageTime}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					完成时间
				</template>
				{{dioData.finishTime}}
			</el-descriptions-item>
			<!-- <el-descriptions-item>
				<template slot="label">
					电梯安装位置
				</template>
				{{dioData.eleAddress}}
			</el-descriptions-item> -->
		</el-descriptions>
		<el-descriptions class="margin-top" :column="1" border>
			<el-descriptions-item>
				<template slot="label">
					电梯安装位置
				</template>
				{{dioData.eleAddress}}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					故障描述
				</template>
				{{dioData.failureDesc}}
			</el-descriptions-item>
		</el-descriptions>
		<div class="tabDiv">
			<timeLin style="width: 100%;" :dioDatas="dioData"/>
		</div>
	</div>
</template>

<script>
	import timeLin from './timeLin.vue'
	export default {
		props: ['dioData', 'flowList'],
		components:{
			timeLin
		},
		data() {
			return {
				// flowList: null
			}
		},
		created() {
			// let data = {
			// 	taskId: this.dioData.id
			// }
			// let url = '/api/ele/web/failureTask/detail'
			// this.$http.post(url, data).then((res) => {
			// 	if (res.data.success) {
			// 		console.log('流程', res.data.data)
			// 		// 排除新状态
			// 		res.data.data.forEach(qs => {
			// 			if (qs.partsList && qs.partsList != '') {
			// 				qs.partsList = JSON.parse(qs.partsList)
			// 			}
			// 			if (qs.afterServiceImgs && qs.afterServiceImgs != '') {
			// 				qs.afterServiceImgs = JSON.parse(qs.afterServiceImgs)
			// 			}
			// 			if (qs.signServiceImgs && qs.signServiceImgs != '') {
			// 				qs.signServiceImgs = JSON.parse(qs.signServiceImgs)
			// 			}
			// 		})
			// 		this.flowList = res.data.data.filter(item => item.status < 10)
			// 	} else {
			// 		this.$message.error(res.data.msg)
			// 	}
			// })
		}
	}
</script>

<style scoped>
	.gdImg {
		width: 100px;
		height: 100px;
		margin-right: 10px;
		margin-top: 10px;
	}

	.tabDiv {
		display: flex;
		width: calc(100%-20px);
		min-height: 20vh;
		padding: 10px;
		color: white;
		/* border: 1px solid white; */
	}
</style>