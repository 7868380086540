<template>
    <el-dialog
        custom-class="custom-dialog"
        title=""
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        append-to-body
        width="1200px"
        top="0"
        @close="closeDialog">
        <div class="">
            <div class="tabBut">
				<!-- <template v-for="item in detailsItem.seleArr">
					<el-button @click="detailsItem.seleId=item.value"
						:type='item.value==detailsItem.seleId?"primary":""'>{{item.label}}</el-button>
				</template> -->
				<el-button @click="detailsItem.seleId=1" :type='1 == detailsItem.seleId ? "primary" : ""'>报警消息
				</el-button>
				<el-button v-show="dioDetailsItem.sosTypeName == '安全回路报警' || dioDetailsItem.sosTypeName == '电梯运行异常告警'" @click="detailsItem.seleId=2"
					:type='2 == detailsItem.seleId ? "primary" : ""'>运行参数</el-button>
				<el-button @click="detailsItem.seleId=3" :type='3==detailsItem.seleId ? "primary" : ""'>处置流程</el-button>
			</div>
			<div style="height: 55vh;margin-top: 10px;" class="scrollElement"
				:class="[detailsItem.seleId==1?'yeScroll':'noScroll']">
				<alarmMessage v-if="detailsItem.seleId==1" :dioData="dioDetailsItem" />
				<operational v-else-if="detailsItem.seleId==2" :dioData="dioDetailsItem" />
				<process v-else :dioData="dioDetailsItem" />
			</div>
			<span slot="footer" class="dialog-footer"
				style="display: flex;align-items: center;justify-content: center;">
				<div v-if="detailsItem.seleId==1 && dioDetailsItem.submitType === 0">
					<el-button  size="mini" type="primary" @click="sosClick(1)" :disabled="disabled">
						<span>人为触发(无故障)</span>
						<p style="margin: 10px 0 0 0;">确认关闭</p>
					</el-button>
					<el-button size="mini" type="info" @click="sosClick(2)" :disabled="disabled">
						<span>电梯故障(无困人)</span>
						<p style="margin: 10px 0 0 0;">派发维修工单</p>
					</el-button>
					<el-button  size="mini" type="warning" @click="sosClick(3)" :disabled="disabled">
						<span>困人</span>
						<p style="margin: 10px 0 0 0;">立即派发救援工单</p>
					</el-button>
                    <el-button
                        size="mini"
                        class="errorSos"
                        @click="sosClick(5)"
                        :disabled="disabled">
                        <span>误报</span>
                        <p style="margin: 10px 0 0 0;">设备产生错误报警</p>
                    </el-button>
				</div>
			</span>
        </div>
        <div class="null"></div>
    </el-dialog>    
</template>
<script>
import alarmMessage from '../../bjgl/bjjl/components/alarmMessage'
import operational from '../../bjgl/bjjl/components/operational'
import process from '../../bjgl/bjjl/components/process'
export default {
    props:{
        detailsItem:Object,
        initDioDetailsItem:Object,
    },
    data() {
        return {
            dialogVisible:true,
            dioDetailsItem:this.initDioDetailsItem,
            disabled:false
        }
    },
    components: {
        alarmMessage,
        operational,
        process
    },
    methods:{
        closeDialog() {
            this.$emit('closeDialog')
        },
        // 详情操作按钮
        sosClick(type) {
            this.disabled = true
            if (this.dioDetailsItem.submitType === 0) {
            this.dioDetailsItem.sosStatus = 2
            this.$http
                .post("/api/ele/app/sos/submit", {
                    id: this.dioDetailsItem.id,
                    submitType: type,
                    sosTime: this.dioDetailsItem.sosTime
                })
                .then((res) => {
                    if (res.data.success) {
                        this.$message.success(res.data.msg)
                    } else {
                        this.$message.error(res.data.msg)
                    }
                    this.closeDialog()
                    // this.onRefer()
                    // this.showDetails(this.tableData.find(item => item.id === this.dioDetailsItem.id))
                })
            }
        },
    },
    created(){
      console.log(this.dioDetailsItem,this.dioDetailsItem.sosTypeName)
    }
}
</script>

<style lang="scss" scoped>
.scrollElement{
    margin:20px 0 0 0;
    // height: 72vh; 
    overflow-y: scroll; 
    padding-right: 20px
}
.errorSos{
  background: #00dcff;
  border-color: #00dcff;
  color: #fff
}
.null{
    height: 90px;
}
.tabBut{
    text-align: center;
}
</style>