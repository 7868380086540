import { render, staticRenderFns } from "./rescue-detail.vue?vue&type=template&id=3f2206cc&scoped=true"
import script from "./rescue-detail.vue?vue&type=script&lang=js"
export * from "./rescue-detail.vue?vue&type=script&lang=js"
import style0 from "./rescue-detail.vue?vue&type=style&index=0&id=3f2206cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f2206cc",
  null
  
)

export default component.exports