<template>
  <el-dialog
    title="选择人员"
    :visible.sync="visible"
    :close-on-click-modal="false"
    append-to-body
    width="1400px"
    top="8vh"
    center
    @close="closeDialog"
  >
    <div
      class="searchBox scrollElement"
      style="height: 68vh"
    >
      <h3>单位名称：{{unitName}}</h3>
      <div class="dataList">
        <el-table
          :data="unitData"
          ref="unitTable"
          border
          style="width: 100%; margin-top: 20px"
          max-height="500px"
          @select="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="55px"
            align="center"
          />
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="50px"
          />
          <el-table-column
            prop="name"
            label="姓名"
            min-width="150"
            align="center"
          />
          <el-table-column
            prop="contactWay"
            label="联系方式"
            min-width="120px"
            align="center"
          />
          <el-table-column
            prop="certificateCode"
            label="作业人员证书编号"
            min-width="150px"
            align="center"
          />
          <el-table-column
            prop="type"
            label="作业证有效时间"
            min-width="160px"
            align="center"
          >
            <template slot-scope="scope">
              {{
                  scope.row.certificateStartTime ? scope.row.certificateStartTime : '-'
              }}
              至
              {{
                  scope.row.certificateEndTime ? scope.row.certificateEndTime : '-'
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="createTime"
            label="添加时间"
            min-width="150px"
            align="center"
          />
        </el-table>
        <Pagination
          :total="unitTotal"
          :page.sync="unitQuery.current"
          :limit.sync="unitQuery.size"
          @pagination="unitPage"
        />
      </div>
    </div>
    <div
      slot="footer"
      class="dialog-footer"
      style="text-align: center"
    >
      <el-button
        v-if="!changeType"
        type="primary"
        @click="determine"
      >保 存</el-button>
      <el-button
        v-else
        type="primary"
        @click="saveChange"
      >保 存</el-button>
      <el-button
        type="indo"
        @click="closeDialog"
      >取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import Pagination from '../../../../components/Pagination'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      loading: true,
      visible: false,
      unitData: [],
      type: '',
      unitTotal: 0,
      unitQuery: {
        current: 1,
        size: 10,
      },
      initdataArr: [],
      updateArr: [],
      updataIds: [],
      addBrandCallback: null, // 弹窗回调
    }
  },
  props: {
    unitName: {
      type: String,
      default: '',
    },
    // 更换
    changeType: {
      type: Boolean,
      default: false,
    },
    initIds: {
      type: Array,
      default: () => [],
    },
    // 单位id
    orgId: {
      type: String,
      default: '',
    },
    unitType: {
      type: String,
      default: '',
    },
  },

  mounted() {},

  methods: {
    // 父字典id，父字典数据，回调
    init(initArr, callback) {
      this.addBrandCallback = callback
      this.type = ''
      this.visible = true
      this.loading = false
      this.updataIds = this.initIds ?? []
      console.log(this.initIds, 'this.initIds');
      this.$nextTick(() => {
        this.getPersonnel()
      })
    },
    // 选中行
    handleSelectionChange(val, row) {
      console.log(val, row,'val, row');
      if (this.updateArr.find((item) => item.id === row.id)) {
        this.updateArr = this.updateArr.filter((item) => item.id !== row.id)
        this.updataIds = this.updataIds.filter((item) => item !== row.id)
      } else {
        if (this.updateArr.length < 10) {
          this.updateArr.push(row)
          this.updataIds.push(row.id)
        } else {
          this.$refs.unitTable.toggleRowSelection(row, false)
          this.$message({
            type: 'warning',
            message: '人员最多能选择10个',
          })
        }
      }
    },

    // 单位分页
    unitPage(data) {
      this.unitQuery.current = data.page
      this.unitQuery.size = data.limit
      this.getPersonnel()
    },
    // 确认选择
    determine() {
      const elevatorData = this.updateArr
      this.updateArr = []
      this.$emit('transferElevator', elevatorData)
      this.visible = false
    },
    // 保存更换
    saveChange() {
      const elevatorData = this.updateArr
      this.$emit('transferElevator', elevatorData)
      this.visible = false
    },

    // 获取列表
    getPersonnel() {
      const data = this.unitQuery
      data.orgId = this.orgId
      if (this.unitType !== 'RESCUE_UNIT') {
        data.type = `*${this.unitType}*`
      }
      this.$http.post('/api/system/web/appUser/getPage', data).then((res) => {
        const {
          data: { data },
        } = res
        if (res.data.code === 200) {
          this.unitData = data.records ?? []
          this.unitTotal = data.total
          this.search = false
          this.$nextTick(() => {
            if (this.unitTotal > 0 && this.$refs.unitTable) {
              this.$refs.unitTable.bodyWrapper.scrollTop = 0;
            }
            if (this.updataIds.length > 0) {
              this.unitData.forEach((ele) => {
                this.updataIds.forEach((item) => {
                  if (item === ele.id) {
                    this.updateArr.push(ele)
                    this.initdataArr.push(ele)
                    this.$refs.unitTable.toggleRowSelection(ele, true)
                  }
                })
              })
            }
          })
        }
      })
    },
    closeDialog() {
      this.visible = false
      const elevatorData = this.initdataArr
      this.$emit('transferElevator', elevatorData)
      this.$refs.unitTable.setCurrentRow()
      this.$refs.unitTable.clearSelection()
    },
  },
}
</script>
<style lang="scss" scoped>
.searchBox {
  /deep/.el-form-item__label {
    color: #00f6ff !important;
    font-size: 14px;
  }
  .searchBtn {
    margin-left: 15px;
  }
}
// 深度选择器 去掉全选按钮
/deep/.el-table__header-wrapper .el-checkbox {
  display: none;
}
</style>
